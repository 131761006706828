import React, { useState, useEffect } from "react";
import axios from "axios";

import { Link, useLocation } from "react-router-dom";
import "../Pages/Category.css";

function Category() {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const category = params.get("category");
  const state = params.get("state");
  const city = params.get("city");
  const adlanguage = params.get("adlanguage");
  const adlocation = params.get("adlocation");
  const receivedname = params.get("name");
  const textquery = params.get("query");
  const caseno = params.get("qry");
  const [crudOperation, setCrudOperation] = useState("read"); // Default to 'read'
  const [items, setItems] = useState([]);
  const [catname, setcatname] = useState([]);
  const [searchqry, setquery] = useState("");

  const [formData, setFormData] = useState({
    id: "",
    name: "",
  });

  const [options, setOptions] = useState([]);
  const [selectedOption, setSelectedOption] = useState("");

  const [selectedcattype, setselectedcattype] = useState("");
  const [cattypeoptions, setcattypeOptions] = useState([]);

  const [selectedlang, setselectedlang] = useState("");
  const [langoptions, setlangOptions] = useState([]);

  const [selectedFile, setSelectedFile] = useState(null);
  const [image, setimage] = useState("");

  const [stateoptions, setstateOptions] = useState([]);
  const [selectedstateOption, setstateSelectedOption] = useState("");

  const [cityoptions, setcityOptions] = useState([]);
  const [selectedcityOption, setcitySelectedOption] = useState("");

  const [editingItemId, setEditingItemId] = useState(null);

  const handleSwitchOperation = (operation) => {
    setCrudOperation(operation);
  };
  useEffect(() => {
    fetchcategorytype();
    fetch();
    fetchstateItems();
    fetchlang();
    fetchItems();
    fetchcatname();
  }, []);

  const fetchcatname = async () => {
    const headers = { "Content-Type": "application/json" };

    const requestBody = {
      tbl: "category",
      query: `where id = ${category} `, // Make sure `category` is a string or a number
      result: "*",
      Token: "1", // Include the token if necessary
    };

    try {
      const response = await axios.post(
        "https://api.myonventure.com/api/CustomerReport/getdata",
        requestBody,
        { headers }
      );
      console.log(response.data);
      setcatname(response.data); // Update state with total orders
    } catch (error) {
      console.error("Error fetching items:", error);
    }
  };

  const fetchItems1 = async () => {
    try {
      const tokens = "1";
      console.log(selectedOption);
      console.log(selectedcattype);
      console.log(selectedlang);
      console.log(formData.adlocation);
      if (typeof formData === "undefined") {
        formData = {};
      }
      if (
        selectedOption === undefined ||
        selectedOption === "undefined" ||
        selectedcattype === ""
      ) {
        setSelectedOption(0); // Use '0' as a string for consistency
      }

      if (
        selectedcattype === undefined ||
        selectedcattype === "undefined" ||
        selectedcattype === ""
      ) {
        setselectedcattype(0); // Use '0' as a string for consistency
      }

      if (
        selectedstateOption === undefined ||
        selectedstateOption === "undefined" ||
        selectedstateOption === ""
      ) {
        setstateSelectedOption(0); // Use '0' as a string for consistency
      }
      if (
        selectedcityOption === undefined ||
        selectedcityOption === "undefined" ||
        selectedcityOption === ""
      ) {
        setcitySelectedOption(0); // Use '0' as a string for consistency
      }
      if (
        selectedlang === undefined ||
        selectedlang === "undefined" ||
        selectedlang === ""
      ) {
        setselectedlang(0); // Use '0' as a string for consistency
      }
      // Handle the case where formData.adlocation is either undefined or the string 'undefined'
      if (
        formData.adlocation === undefined ||
        formData.adlocation === "undefined"
      ) {
        formData.adlocation = "0"; // Use '0' as a string for consistency
      }
      const response = await axios.get(
        "https://api.myonventure.com/api/SubCategory/subcategoryadminsearch?id=" +
          selectedOption +
          "&cattype=" +
          selectedcattype +
          "&lang=" +
          selectedlang +
          "&adlocation=" +
          formData.adlocation +
          "&state=" +
          selectedstateOption +
          "&city=" +
          selectedcityOption +
          "&tokens=" +
          tokens +
          ""
      );
      setItems(response.data);
    } catch (error) {
      console.error("Error fetching items:", error);
    }
  };

  const fetchcategorytype = async () => {
    try {
      const tokens = "1";
      const response = await axios.get(
        "https://api.myonventure.com/api/Categories/categoryall?tokens=" +
          tokens
      );
      // Ensure the response is an array
      if (Array.isArray(response.data)) {
        setcattypeOptions(response.data);
      } else {
        console.error(
          "Expected an array of category types, but got:",
          response.data
        );
        setcattypeOptions([]); // Set to empty array if the data is not an array
      }
    } catch (error) {
      console.error("Error fetching category types:", error);
    }
  };

  const fetchlang = async () => {
    try {
      const tokens = "1";
      const response = await axios.get(
        "https://api.myonventure.com/api/Adlanguage/languageall?tokens=" +
          tokens +
          ""
      );
      setlangOptions(response.data);
    } catch (error) {
      console.error("Error fetching items:", error);
    }
  };

  const fetch = async () => {
    const response1 = await axios.get(
      "https://api.myonventure.com/api/Categories/categoryall?tokens=1"
    );

    // Update state with fetched data
    setOptions(response1.data);
  };

  const fetchstateItems = async () => {
    try {
      const tokens = "1";
      const response = await axios.get(
        "https://api.myonventure.com/api/state/state?tokens=" + tokens + ""
      );
      setstateOptions(response.data);
    } catch (error) {
      console.error("Error fetching items:", error);
    }
  };

  const fetchcityItems = async (id) => {
    try {
      const tokens = "1";
      const response = await axios.get(
        "https://api.myonventure.com/api/city/Stateid?id=" +
          id +
          "&tokens=" +
          tokens +
          ""
      );
      setcityOptions(response.data);
    } catch (error) {
      console.error("Error fetching items:", error);
    }
  };

  const createQueryString = ({
    state,
    city,
    category,
    adlanguage,
    adlocation,
  }) => {
    const queryParts = [];

    if (state) {
      queryParts.push(`state='${encodeURIComponent(state)}'`);
    }
    if (city) {
      queryParts.push(`city='${encodeURIComponent(city)}'`);
    }
    if (category) {
      queryParts.push(`category='${encodeURIComponent(category)}'`);
    }
    if (adlanguage) {
      queryParts.push(`adlanguage='${encodeURIComponent(adlanguage)}'`);
    }
    if (adlocation) {
      queryParts.push(`adlocation='${encodeURIComponent(adlocation)}'`);
    }

    // Join all parts with ' AND '
    return queryParts.length > 0 ? `WHERE ${queryParts.join(" AND ")}` : "";
  };

  const fetchItems = async () => {
    //console.log(receivedValue);
    const headers = { "Content-Type": "application/json" };
    console.log(category);
    console.log(city);
    if (caseno === "1") {
      const requestBody = {
        tbl: "subcategory",
        query: `where category = ${category} order by id`, // Make sure `category` is a string or a number
        result: "*",
        Token: "1", // Include the token if necessary
      };

      try {
        const response = await axios.post(
          "https://api.myonventure.com/api/CustomerReport/getdata",
          requestBody,
          { headers }
        );
        console.log(response.data);
        setItems(response.data); // Update state with total orders
      } catch (error) {
        console.error("Error fetching items:", error);
      }
    }

    //query 2
    if (caseno === "2") {
      console.log(city);

      const requestBody = {
        tbl: "subcategory",
        // Ensure proper quoting for string values
        query: `where category= ${category} and city= ${city} order by id`, // Fixed syntax
        result: "*",
        Token: "1", // Include the token if necessary
      };

      try {
        const response = await axios.post(
          "https://api.myonventure.com/api/CustomerReport/getdata",
          requestBody,
          { headers }
        );
        console.log(response.data);
        setItems(response.data); // Update state with total orders
      } catch (error) {
        console.error("Error fetching items:", error);
      }
    }

    //query 2
    if (caseno === "3") {
      const query = createQueryString({
        state,
        city,
        category,
        adlanguage,
        adlocation,
      });

      const requestBody = {
        tbl: "subcategory",
        // Ensure proper quoting for string values

        query: query, // Fixed syntax
        result: "*",
        Token: "1", // Include the token if necessary
      };

      try {
        const response = await axios.post(
          "https://api.myonventure.com/api/CustomerReport/getdata",
          requestBody,
          { headers }
        );
        console.log(response.data);
        setItems(response.data); // Update state with total orders
      } catch (error) {
        console.error("Error fetching items:", error);
      }
    }

    if (caseno === "4") {
      const splitText = textquery.split("in");
      console.log(splitText);

      if (splitText.length === 1) {
        const categoryName = splitText[0].trim();
        const qr = `where category in (select id from category where name='${categoryName}')  order by id`;
        setquery(qr);
      } else {
        const categoryName = splitText[0].trim();
        const locationName = splitText[1].trim();
        const qr = `where category in (select id from category where name='${categoryName}') and (city in (select id from city where name='${locationName}') or state in (select id from state where name='${locationName}')) order by id`;
        setquery(qr);
      }

      const requestBody = {
        tbl: "subcategory",
        query: { searchqry },
        result: "*",
        Token: "1",
      };

      try {
        const response = await axios.post(
          "https://api.myonventure.com/api/CustomerReport/getdata",
          requestBody,
          { headers }
        );
        console.log(response.data);
        setItems(response.data); // Update state with total orders
      } catch (error) {
        console.error("Error fetching items:", error);
      }
    }
  };
  const setData = async (id) => {
    setSelectedOption(id);

    setFormData({ ...formData, category: id });
  };

  const setcattypeData = async (id) => {
    setselectedcattype(id);

    setFormData({ ...formData, categorytype: id });
  };
  const setlangData = async (id) => {
    setselectedlang(id);

    setFormData({ ...formData, adlanguage: id });
  };
  const setstateData = async (id) => {
    setstateSelectedOption(id);

    setFormData({ ...formData, state: id });
    fetchcityItems(id);
  };

  const setcityData = async (id) => {
    setcitySelectedOption(id);

    setFormData({ ...formData, city: id });
  };
  const handlesearch = async () => {
    window.location.href = `/Category?category=${selectedOption}&state=${selectedstateOption}&city=${selectedcityOption}&qry=3`;
  };

  return (
    <div className="header">
      <div className="container ">
        <div className="row  innerheader">
          <div className="col-12 col-md-4 mt-4">
            <h4>
              <span style={{ textTransform: "capitalize" }}> {} </span>
              Advertising
            </h4>{" "}
          </div>
          {/* <div className="col col-md-5"></div> */}
          <div className="col-12 col-md-8 text-right  mt-4">
            {/*    <nav className='pull-right' >
        <ol class="breadcrumb " style={{float:'right'}}>
        <li class="breadcrumb-item"><a href="/">Home</a></li>
        <li class="breadcrumb-item breadcrumbtext active pull-right" aria-current="page" > {receivedname}</li>
        </ol>
        </nav> */}

            <div
              id="search"
              className="row text-right pb-2"
              style={{ textAlign: "right" }}
            >
              <div class="form-group col-2">
                <select
                  className="form-control"
                  value={selectedstateOption}
                  onChange={(e) => setstateData(e.target.value)}
                >
                  <option value=""> State</option>
                  {stateoptions.map((option) => (
                    <option key={option.id} value={option.id}>
                      {option.name}
                    </option>
                  ))}
                </select>
              </div>
              <div class="form-group col-2">
                <select
                  className="form-control"
                  value={selectedcityOption}
                  onChange={(e) => setcityData(e.target.value)}
                >
                  <option value="">City</option>
                  {cityoptions.map((option) => (
                    <option key={option.id} value={option.id}>
                      {option.name}
                    </option>
                  ))}
                </select>
              </div>
              <div class="form-group col-2">
                <select
                  className="form-control"
                  value={selectedOption}
                  onChange={(e) => setData(e.target.value)}
                >
                  <option value="0">Category</option>
                  {options.map((option) => (
                    <option key={option.id} value={option.id}>
                      {option.name}
                    </option>
                  ))}
                </select>
              </div>

              <div class="form-group col-2">
                <select
                  className="form-control"
                  value={selectedlang}
                  onChange={(e) => setlangData(e.target.value)}
                >
                  <option value="0">Language</option>
                  {langoptions.map((option) => (
                    <option key={option.id} value={option.id}>
                      {option.name}
                    </option>
                  ))}
                </select>
              </div>

              <div class="form-group col-2">
                <select
                  className="form-control"
                  value={formData.adlocation}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      adlocation: e.target.value,
                    })
                  }
                >
                  <option value="0">AD Location</option>
                  <option value="INTER NATIONAL" key="INTER NATIONAL">
                    INTER NATIONAL
                  </option>
                  <option value="NATIONAL" key="NATIONAL">
                    NATIONAL
                  </option>
                  <option value="REGIONAL" key="REGIONAL">
                    REGIONAL
                  </option>
                </select>
              </div>
              <div class="form-group col-1">
                <button
                  className="btn  btn-primary"
                  onClick={() => handlesearch()}
                >
                  <i className="fa fa-search"></i>
                </button>
              </div>
            </div>
          </div>
          <hr className="hr-12" />
        </div>

        <div className="row">
          {items.map((item, index) => {
            const itemname = item.name;
            const topq = (item.topquestion1 + ": " + item.topans1).substring(
              0,
              32
            );
            if (topq > 32) {
              topq =
                (item.topquestion1 + ": " + item.topans1).substring(0, 34) +
                "...";
            }
            return (
              <div className="col col-12 col-md-3 mb-3">
                <Link
                  to={{ pathname: "/Subcat", search: "?value=" + item.id + "" }}
                  className="cardlink"
                >
                  <div className="card cardstyle">
                    <img
                      className="img-responsive imgcat"
                      src={
                        "https://api.myonventure.com/api/../images/" +
                        item.image
                      }
                    />

                    <div className="card-body">
                      <h5 className="card-title tooltip-test" title={item.name}>
                        {itemname}
                      </h5>

                      <p
                        className="card-text textgreen  tooltip-test"
                        title={item.topquestion1 + ": " + item.topans1}
                      >
                        <i class="fa-solid fa-circle-right textpink"></i> {topq}{" "}
                      </p>
                      <p className="card-text textgreen">
                        <i class="fa-solid fa-circle-right textpink"></i>{" "}
                        {item.topquestion2}: {item.topans2}
                      </p>

                      {/*   <a href="#" className="btn btn-primary">Go somewhere</a> */}
                    </div>
                  </div>
                </Link>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}
export default Category;
