import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import "../Pages/Mediaregistration.css";
const Mediaregistration = () => {
  useEffect(() => {
    //  scrollToTop();
  });

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Optional: for a smooth scrolling effect
    });
  };

  const [formData, setFormData] = useState({
    companyName: "",
    companyImage: null,
    registeredAddress: "",
    gstDetails: "",
    panCardDetails: "",
    communicationAddress: "",
    city: "",
    email: "",
    contactPerson1: "",
    contactPerson2: "",
    contactPerson3: "",
    mobile1: "",
    mobile2: "",
    mobile3: "",
    operationalCities: "",
    statesOfOperation: [],
    mediaTypes: [],

    refname1: "",
    refcontactNo1: "",
    refname2: "",
    refcontactNo2: "",
    refname3: "",
    refcontactNo3: "",

    davpRegistered: false,
    aadaiMember: false,
    davpDocs: null,
    aadaiDocs: null,
  });
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);

  const handleChange = (e) => {
    const { name, value, type, files } = e.target;

    if (type === "file") {
      setFormData({ ...formData, [name]: files[0] });
    } else if (name.startsWith("refname") || name.startsWith("refcontactNo")) {
      setFormData({ ...formData, [name]: value });
    } else if (name === "statesOfOperation") {
      const selectedStates = Array.from(
        e.target.selectedOptions,
        (option) => option.value
      );
      setFormData({ ...formData, statesOfOperation: selectedStates });
    } else if (name.startsWith("mediaType")) {
      const mediaType = value;
      const mediaTypes = formData.mediaTypes.includes(mediaType)
        ? formData.mediaTypes.filter((type) => type !== mediaType)
        : [...formData.mediaTypes, mediaType];
      setFormData({ ...formData, mediaTypes });
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formDataToSend = new FormData();

    Object.keys(formData).forEach((key) => {
      if (Array.isArray(formData[key])) {
        formData[key].forEach((item, index) => {
          formDataToSend.append(`${key}[${index}]`, item);
        });
      } else {
        formDataToSend.append(key, formData[key]);
      }
    });

    try {
      const response = await axios.post(
        "/api/mediaowner/register",
        formDataToSend,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      if (response.data.success) {
        setSuccess(true);
      } else {
        setError(response.data.message);
      }
    } catch (err) {
      setError("Registration failed. Please try again.");
    }
  };

  return (
    <div className="header" id="mdregister">
      <div className="container">
        <div className="row innerheader">
          <div>
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-xl-10 col-lg-12 col-md-9">
                  <div className="card o-hidden border-0 shadow-lg my-3">
                    <div className="card-body p-0">
                      <div className="row p-5">
                        {/*  <div className="alert alert-primary text-center">
                            <h1 className="h4 text-gray-900 mb-2">
                              Registration!
                            </h1>
                          </div> */}
                        <form onSubmit={handleSubmit}>
                          <h2>Media Owner Registration</h2>
                          {error && <div style={{ color: "red" }}>{error}</div>}
                          {success && (
                            <div style={{ color: "green" }}>
                              Registration successful! Please check your email
                              for verification.
                            </div>
                          )}
                          <div className="row">
                            <div className="col-12 col-md-6 form-group">
                              <label htmlFor="logo">Company Name</label>
                              <br />
                              <input
                                type="text"
                                name="companyName"
                                placeholder="Company Name"
                                onChange={handleChange}
                                required
                              />
                            </div>
                            <div className="col-12 col-md-6 form-group">
                              <label htmlFor="logo">Company Logo</label> <br />
                              <input
                                type="file"
                                name="companyImage"
                                onChange={handleChange}
                                required
                              />
                            </div>
                            <div className="col-12 col-md-6 form-group">
                              <label htmlFor="Address">
                                Registered Address
                              </label>{" "}
                              <br />
                              <input
                                type="text"
                                name="registeredAddress"
                                placeholder="Registered Address"
                                onChange={handleChange}
                                required
                              />
                            </div>
                            <div className="col-12 col-md-6 form-group">
                              <label htmlFor="gst">GST Details</label> <br />
                              <input
                                type="text"
                                name="gstDetails"
                                placeholder="GST Details"
                                onChange={handleChange}
                                required
                              />
                            </div>
                            <div className="col-12 col-md-6 form-group">
                              <label htmlFor="Address">PAN Card Details</label>{" "}
                              <br />
                              <input
                                type="text"
                                name="panCardDetails"
                                placeholder="PAN Card Details"
                                onChange={handleChange}
                                required
                              />
                            </div>
                            <div className="col-12 col-md-6 form-group">
                              <label htmlFor="Address">
                                Address for Communication
                              </label>{" "}
                              <br />
                              <input
                                type="text"
                                name="communicationAddress"
                                placeholder="Address for Communication"
                                onChange={handleChange}
                                required
                              />
                            </div>
                            <div className="col-12 col-md-6 form-group">
                              <label htmlFor="Address">City</label> <br />
                              <input
                                type="text"
                                name="city"
                                placeholder="City"
                                onChange={handleChange}
                                required
                              />
                            </div>
                            <div className="col-12 col-md-6 form-group">
                              <label htmlFor="Address">Email</label> <br />
                              <input
                                type="email"
                                name="email"
                                placeholder="Email"
                                onChange={handleChange}
                                required
                              />
                            </div>
                            <div className="col-12 col-md-6 form-group">
                              <label htmlFor="Address">
                                Contact Person Name 1
                              </label>{" "}
                              <br />
                              <input
                                type="text"
                                name="contactPerson1"
                                placeholder="Contact Person Name 1"
                                onChange={handleChange}
                                required
                              />
                            </div>{" "}
                            <div className="col-12 col-md-6 form-group">
                              <label htmlFor="Address">
                                Contact Details 1 (Mobile No)
                              </label>{" "}
                              <br />
                              <input
                                type="text"
                                name="mobile1"
                                placeholder="Contact Details 1 (Mobile No)"
                                onChange={handleChange}
                                required
                              />
                            </div>
                            <div className="col-12 col-md-6 form-group">
                              <label htmlFor="Address">
                                Contact Person Name 2
                              </label>{" "}
                              <br />
                              <input
                                type="text"
                                name="contactPerson2"
                                placeholder="Contact Person Name 2"
                                onChange={handleChange}
                              />
                            </div>
                            <div className="col-12 col-md-6 form-group">
                              <label htmlFor="Address">
                                Contact Details 2 (Mobile No)
                              </label>{" "}
                              <br />
                              <input
                                type="text"
                                name="mobile2"
                                placeholder="Contact Details 2 (Mobile No)"
                                onChange={handleChange}
                              />
                            </div>
                            <div className="col-12 col-md-6 form-group">
                              <label htmlFor="Address">
                                Contact Person Name 3
                              </label>{" "}
                              <br />
                              <input
                                type="text"
                                name="contactPerson3"
                                placeholder="Contact Person Name 3"
                                onChange={handleChange}
                              />
                            </div>
                            <div className="col-12 col-md-6 form-group">
                              <label htmlFor="Address">
                                Contact Details 3 (Mobile No)
                              </label>{" "}
                              <br />
                              <input
                                type="text"
                                name="mobile3"
                                placeholder="Contact Details 3 (Mobile No)"
                                onChange={handleChange}
                              />
                            </div>
                            <div className="col-12 col-md-6 form-group">
                              <label htmlFor="Address">
                                Operational Cities
                              </label>{" "}
                              <br />
                              <input
                                type="text"
                                name="operationalCities"
                                placeholder="Operational Cities"
                                onChange={handleChange}
                                required
                              />
                            </div>
                            <div className="col-12 col-md-6 form-group">
                              <label htmlFor="Address">
                                States Of Operation
                              </label>{" "}
                              <br />
                              <select
                                multiple
                                name="statesOfOperation"
                                onChange={handleChange}
                              >
                                <option value="State1">State 1</option>
                                <option value="State2">State 2</option>
                                <option value="State3">State 3</option>
                                {/* Add more states as needed */}
                              </select>
                            </div>
                            <div className="col-12 col-md-6 form-group">
                              <h4>Type of Media Owned:</h4>
                              <label>
                                {" "}
                                <br />
                                <input
                                  type="checkbox"
                                  name="mediaType"
                                  value="Hoardings"
                                  onChange={handleChange}
                                />{" "}
                                Hoardings
                              </label>
                              <label>
                                <input
                                  type="checkbox"
                                  name="mediaType"
                                  value="City Buses"
                                  onChange={handleChange}
                                />{" "}
                                City Buses
                              </label>
                              <label>
                                <input
                                  type="checkbox"
                                  name="mediaType"
                                  value="State Transport Buses"
                                  onChange={handleChange}
                                />{" "}
                                State Transport Buses
                              </label>
                              {/* Add more media types as needed */}
                            </div>
                            <div className="col-12 col-md-12 form-group">
                              <h4>Professional References:</h4>
                              <div className="col-12 col-md-6 form-group">
                                <input
                                  type="text"
                                  name="refname1"
                                  placeholder="Reference 1 Name"
                                  onChange={handleChange}
                                />
                              </div>{" "}
                              <div className="col-12 col-md-6 form-group">
                                <input
                                  type="text"
                                  name="refcontactNo1"
                                  placeholder="Reference 1 Contact No"
                                  onChange={handleChange}
                                />
                              </div>{" "}
                              <div className="col-12 col-md-6 form-group">
                                <input
                                  type="text"
                                  name="refname2"
                                  placeholder="Reference 2 Name"
                                  onChange={handleChange}
                                />
                              </div>{" "}
                              <div className="col-12 col-md-6 form-group">
                                <input
                                  type="text"
                                  name="refcontactNo2"
                                  placeholder="Reference 2 Contact No"
                                  onChange={handleChange}
                                />
                              </div>{" "}
                              <div className="col-12 col-md-6 form-group">
                                <input
                                  type="text"
                                  name="refname3"
                                  placeholder="Reference 3 Name"
                                  onChange={handleChange}
                                />
                              </div>{" "}
                              <div className="col-12 col-md-6 form-group">
                                <input
                                  type="text"
                                  name="refcontactNo3"
                                  placeholder="Reference 3 Contact No"
                                  onChange={handleChange}
                                />
                              </div>{" "}
                              <div className="col-12 col-md-6 form-group"></div>
                            </div>
                            <label>
                              <input
                                type="checkbox"
                                name="davpRegistered"
                                checked={formData.davpRegistered}
                                onChange={handleChange}
                              />
                              Are you DAVP Registered Media Owner/Agency?
                            </label>
                            {formData.davpRegistered && (
                              <input
                                type="file"
                                name="davpDocs"
                                onChange={handleChange}
                              />
                            )}
                            <label>
                              <input
                                type="checkbox"
                                name="aadaiMember"
                                checked={formData.aadaiMember}
                                onChange={handleChange}
                              />
                              Are you a Member of Advertising Agency Association
                              of India?
                            </label>
                            {formData.aadaiMember && (
                              <input
                                type="file"
                                name="aadaiDocs"
                                onChange={handleChange}
                              />
                            )}
                            <button type="submit">Register</button>
                          </div>
                        </form>
                        <hr />
                        <div className="text-center">
                          Already Registered!{" "}
                          <Link className="small" to="/Login">
                            Login Now
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="modal" id="myModal">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title">Terms of Use</h4>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                ></button>
              </div>
              <div className="modal-body">
                {/* Terms of Use Content */}
                <p>...</p>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-danger"
                  data-bs-dismiss="modal"
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Mediaregistration;
